// Core

@import "widgets/bootstrap/scss/functions";

@import "widgets/bootstrap/scss/variables";
@import "core/variables";

@import "widgets/bootstrap/scss/mixins";
@import "core/mixins";

// Utilities

@import "core/utilities/helpers";
@import "core/utilities/background";
@import "core/utilities/demo";

// Bootstrap base

@import "widgets/bootstrap/base";

.app-sidebar--dark {
    background: #926e34 !important;
    .sidebar-navigation {
        ul {
            li {
                a {
                    color: #fff !important;
                }
            }
        }
    }
}

#notification-wrapper {
    .toast-notification {
        z-index: 9999 !important;
        // bottom: 50px !important;
        // top: initial !important;
        // span {
        //     border-radius: 4px !important;
        // }
    }
}

.rw-widget {
    &.rw-multiselect {
        .rw-multiselect-tag {
            margin-top: calc(0.279335em - 7px);
        }

        .rw-widget-container {
            border: 1px solid #d1d2db;
        }

        // .rw-state-focus > .rw-widget-container,
        // .rw-state-focus > .rw-widget-container:hover {
        //     box-shadow: 0 0.313rem 0.719rem rgba(122, 123, 151, 0.1), 0 0.156rem 0.125rem rgba(122, 123, 151, 0.06) !important;
        //     border-color: #9297da !important;
        // }

        // .rw-state-focus > .rw-widget-container {
        //     &:hover {
        //         box-shadow: 0 0.313rem 0.719rem rgba(122, 123, 151, 0.1), 0 0.156rem 0.125rem rgba(122, 123, 151, 0.06) !important;
        //         border-color: #9297da !important;
        //     }
        //     box-shadow: 0 0.313rem 0.719rem rgba(122, 123, 151, 0.1), 0 0.156rem 0.125rem rgba(122, 123, 151, 0.06) !important;
        //     border-color: #9297da !important;
        // }
    }
}
