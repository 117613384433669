// Core

@import "core";

// Fonts

// @import url('https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@400;700&family=Hind+Guntur:wght@400;500;700&family=Mukta+Malar:wght@400;700&family=Quicksand:wght@400;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
// Widgets

@import "widgets";

// Components

@import "components";

// Layout Components

@import "layout-components";

// Layout Blueprints

@import "layout-blueprints";
