.main-carousel {
    margin-left: 135px;
    margin-right: 135px;
    margin-bottom: 10px;

    @media (max-width: 992px) {
        margin: 0;
        padding: 40px;
    }

    @media (max-width: 576px) {
        padding: 0 0 40px 0;
    }

    img {
        width: 100%;
        height: 550px;
        object-fit: cover;

        @media (max-width: 992px) {
            height: 500px !important;
        }

        @media (max-width: 576px) {
            height: 350px !important;
        }
    }

    .carousel-caption {
        width: 100%;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: block !important;

        h3 {
            font-size: 32px;

            @media (max-width: 576px) {
                margin-bottom: 5px;
                font-size: 24px;
            }
        }

        p {
            font-size: 18px;

            @media (max-width: 576px) {
                font-size: 12px;
                margin-bottom: 7px;
            }
        }
    }

    .carousel-indicators {
        margin-bottom: 0;

        @media (max-width: 576px) {
            bottom: 40px;
        }

        li {
            width: 10px;
            height: 10px;
            border-radius: 100%;
        }
    }
}
