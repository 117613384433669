#main-header-container {
    padding: 12px 25px 0 25px;
    @media (max-width: 576px) {
        font-size: 13px;
    }

    .left-hamburger-menu {
        cursor: pointer;
        @media (min-width: 768px) {
            display: none;
        }
    }
}

#main-logo {
    width: 200px;

    @media (max-width: 576px) {
        width: 110px;
    }
}

#slogan {
    font-style: italic;
    display: block;
    margin-top: 5px;
    text-align: center;
    margin-bottom: 10px;

    @media (max-width: 576px) {
        font-size: 10px;
    }
}
