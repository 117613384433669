#custom-layout {
    padding: 40px 135px;

    @media (max-width: 992px) {
        padding: 40px;
    }

    @media (max-width: 576px) {
        padding: 0 20px 30px 20px;
    }
}
