#header-top-menu-container {
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    margin-left: 135px;
    margin-right: 135px;

    @media (max-width: 768px) {
        display: none;
    }

    .navbar {
        @media (max-width: 768px) {
            display: none;
        }
    }

    .container {
        @media (max-width: 768px) {
            padding: 0;
            max-width: none;
        }
    }

    .dropdown {
        &.nav-item {
            .nav-link {
                text-transform: uppercase;
                // font-weight: bold;
                color: #000;
                &:hover {
                    color: #936e37;
                }
            }
        }
        .dropdown-item.active {
            background-color: #fff;
            &:hover {
                background-color: #f4f5fd;
            }
        }

        .dropdown-toggle {
            &::after {
                @media (max-width: 768px) {
                    float: right;
                }
            }
        }

        .dropdown-menu {
            @media (max-width: 768px) {
                box-shadow: none;
            }
        }
    }

    .header-top-menu-item {
        text-transform: uppercase;
        // font-weight: bold;
        color: #000;
        position: relative;
        top: 8px;
        &:hover {
            color: #936e37;
        }
    }

    .drop-menu-link {
        &:hover {
            color: #936e37;
        }
    }

    .navbar-nav {
        .nav-item:not(.dropdown) {
            @media (max-width: 768px) {
                margin-bottom: 12px;
            }
        }
    }
}
