.left-sidebar-container {
    width: 250px;
    background-color: #fff;
    position: fixed;
    height: 100%;
    z-index: 11;
    top: 0;
    padding: 20px;

    .close-menu-button {
        cursor: pointer;
    }

    .sidebar-navigation {
        a {
            padding: 0;
        }
    }
}
