#footer-menu-container {
    background-color: #916e34;
    padding-top: 20px;
    padding-left: 20px;
    color: #fff;

    @media (max-width: 576px) {
        padding-left: 0;
    }

    a {
        color: #fff;
        &:hover {
            text-decoration: underline;
        }
    }

    ul {
        padding: 0;
        li {
            list-style: none;
        }
    }

    // .footer-menu-item {
    //     text-transform: uppercase;
    // }

    .childrens {
        margin-top: 30px;
    }

    .item {
        @media (max-width: 768px) {
            min-width: 150px;
            margin-bottom: 50px;
        }

        @media (max-width: 576px) {
            min-width: 140px;
            margin-bottom: 50px;
        }
    }

    .index {
        @media (max-width: 576px) {
            min-width: 140px;
        }
    }
}

#footer2 {
    background-color: #7f5f2a;
    padding: 10px 93px;
    color: #fff;
    font-size: 14.26px;

    a {
        color: #fff;
        &:hover {
            color: #fff;
        }
    }
}
